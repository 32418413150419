import { render, staticRenderFns } from "./TimelineItem.vue?vue&type=template&id=50288475&scoped=true&"
import script from "./TimelineItem.vue?vue&type=script&lang=ts&"
export * from "./TimelineItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50288475",
  null
  
)

export default component.exports