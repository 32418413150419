



























































































































































































































































































































































































import Vue from "vue";
import Snackbar from "@/components/shared/Snackbar.vue";
import ProgressBlock from "@/components/shared/ProgressBlock.vue";
import TimelineItem from "@/components/shared/TimelineItem.vue";
import SkillsItem from "@/components/shared/SkillsItem.vue";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth";
export default Vue.extend({
  data: (): Record<string, any> => ({
    profile_picture: require("@/assets/profile_pictures/user_placeholder.jpg"),
    //snackbar
    snackbar_notification: false,
    upskillingIndex1: 0,
    upskillingIndex2: 1,
    notification: "",
    timelineItems: [
      {
        title: "Software Project Manager", // TODO: Replace from backend
        icon: require("@/assets/icons/arrows/up.svg"),
        alt: "Timeline icon",
        active: false
      },
      {
        title: "Software Architect", // TODO: Replace from backend
        icon: require("@/assets/icons/arrows/up.svg"),
        alt: "Timeline icon",
        active: false
      },
      {
        title: "Cloud Engineer", // TODO: Replace from backend
        icon: require("@/assets/icons/arrows/up.svg"),
        alt: "Timeline icon",
        active: false
      },
      {
        title: "Sr. Software Engineer", // TODO: Replace from backend
        icon: require("@/assets/icons/arrows/up.svg"),
        alt: "Timeline icon",
        active: true
      },
      {
        title: "Software Engineer", // TODO: Replace from backend
        icon: require("@/assets/icons/arrows/up.svg"),
        alt: "Timeline icon",
        active: false
      }
    ],
    skillsItems: [
      {
        title: "Build business relationships", // TODO: Replace from backend
        labelTitle: "Leadership Management", // TODO: Replace from backend
        labelClasses: "custom-text-red custom-background-red-5",
        desiredValue: 20,
        currentValue: 60
      },
      {
        title: "Train employees", // TODO: Replace from backend
        labelTitle: "Technical Skills", // TODO: Replace from backend
        labelClasses: "custom-text-teal-blue custom-background-teal-blue-5",
        desiredValue: 50,
        currentValue: 80
      },
      {
        title: "Perform resource planning", // TODO: Replace from backend
        labelTitle: "Soft skills", // TODO: Replace from backend
        labelClasses: "custom-text-dark-yellow custom-background-yellow-10",
        desiredValue: 40,
        currentValue: 40
      },
      {
        title: "Perform project management", // TODO: Replace from backend
        labelTitle: "Hardware Skills", // TODO: Replace from backend
        labelClasses: "custom-text-dark-green custom-background-green-5",
        desiredValue: 90,
        currentValue: 60
      }
    ],
    yearlyUpskillingItems: [
      {
        title: "Data Analyst",
        description:
          "Advance your programming skills and refine your ability to work with data",
        logo: require("@/assets/logos/udacity.svg")
      },
      {
        title: "AI for Trading Learning",
        description:
          "Learn the basics of quantitative analysis, including data processing and portfolio management",
        logo: require("@/assets/logos/udacity.svg")
      },
      {
        title: "Become a Data Product Manager",
        description:
          "Undergraduate degrees from Khalifa University of Science and Technology are designed",
        logo: require("@/assets/logos/udacity.svg")
      }
    ],
    options: {
      chart: {
        id: "skills-in-demand-chart",
        toolbar: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      xaxis: {
        type: "numeric",
        labels: {
          show: true,
          formatter: function (value: number) {
            return value + "%";
          }
        },
        categories: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          borderRadiusApplication: "end",
          barHeight: "79%"
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        horizontalAlign: "left",
        itemMargin: {
          horizontal: 15
        },
        markers: {
          radius: 50
        }
      },
      tooltip: {
        enabled: false
      },
      grid: {
        show: true,
        borderColor: "#EDEFF2",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    },
    series: []
  }),
  created() {
    setTimeout(() => {
      this.series = [
        {
          name: "Software Developer",
          data: [
            {
              x: 90,
              y: 90
            }
          ],
          color: "#CC7429"
        },
        {
          name: "Blockchain",
          data: [
            {
              x: 70,
              y: 70
            }
          ],
          color: "#3361FF"
        },
        {
          name: "Cloud Computing",
          data: [
            {
              x: 50,
              y: 50
            }
          ],
          color: "#00B211"
        }
      ];
    }, 150);
  },
  components: {
    Snackbar,
    ProgressBlock,
    TimelineItem,
    SkillsItem,
    VueApexCharts
  },
  methods: {
    snackbar_notification_click() {
      this.notification = "";
      this.snackbar_notification = false;
    },
    nextUpskillingItemClick() {
      if (this.upskillingIndex2 === this.yearlyUpskillingItems.length - 1) {
        this.upskillingIndex1 = this.upskillingIndex1 + 1;
        this.upskillingIndex2 = 0;
      } else if (
        this.upskillingIndex1 ===
        this.yearlyUpskillingItems.length - 1
      ) {
        this.upskillingIndex1 = 0;
        this.upskillingIndex2 = this.upskillingIndex2 + 1;
      } else {
        this.upskillingIndex1 = this.upskillingIndex1 + 1;
        this.upskillingIndex2 = this.upskillingIndex2 + 1;
      }
    },
    prevUpskillingItemClick() {
      if (this.upskillingIndex2 === 0) {
        this.upskillingIndex1 = this.upskillingIndex1 - 1;
        this.upskillingIndex2 = this.yearlyUpskillingItems.length - 1;
      } else if (this.upskillingIndex1 === 0) {
        this.upskillingIndex1 = this.yearlyUpskillingItems.length - 1;
        this.upskillingIndex2 = this.upskillingIndex2 - 1;
      } else {
        this.upskillingIndex1 = this.upskillingIndex1 - 1;
        this.upskillingIndex2 = this.upskillingIndex2 - 1;
      }
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  }
});
