

























import Vue from "vue";
export default Vue.extend({
  name: "TimelineItem",
  props: {
    title: String,
    icon: String,
    alt: String,
    active: Boolean
  }
});
