



































































import Vue from "vue";
export default Vue.extend({
  name: "SkillsItem",
  props: {
    title: String,
    labelClasses: String,
    labelTitle: String,
    desiredValue: Number,
    currentValue: Number
  }
});
